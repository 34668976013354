import { Pipe, PipeTransform } from '@angular/core';
import { Countries } from '@models/countries';

@Pipe({
  name: 'country',
})
export class CountryPipe implements PipeTransform {
  private countries = Countries;

  transform(value: any): any {
    return this.countries[value];
  }
}
