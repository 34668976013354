export enum FamilySpaceStepType {
  CEREMONY = 'ceremony',
  RESTING_PLACE = 'resting_place',
  COFFIN_PLACING = 'coffin_placing',
  RECOLLECTION = 'recollection',
  CREMATION = 'cremation',
  ASHES = 'ashes',
  INHUMATION = 'inhumation',
  COLLATION = 'collation',
  CUSTOM = 'custom',
}

// Partial because we dont want CEREMONY
export const familySpaceStepTypeTranslations: Partial<Record<FamilySpaceStepType, string>> = {
  [FamilySpaceStepType.RESTING_PLACE]: 'Lieu de repos',
  [FamilySpaceStepType.COFFIN_PLACING]: 'Mise en bière',
  [FamilySpaceStepType.RECOLLECTION]: 'Recueillement',
  [FamilySpaceStepType.CREMATION]: 'Crémation',
  [FamilySpaceStepType.ASHES]: 'Destination des cendres',
  [FamilySpaceStepType.INHUMATION]: 'Inhumation',
  [FamilySpaceStepType.COLLATION]: 'Collation',
  [FamilySpaceStepType.CUSTOM]: 'Évènement personnalisé',
};
