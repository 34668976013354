import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalData } from '@models/confirm-modal-data';

@Component({
  selector: 'app-mconfirm-modal',
  templateUrl: './mconfirm-modal.component.html',
  styleUrls: ['./mconfirm-modal.component.scss'],
})
export class MconfirmModalComponent {
  public constructor(
    public dialogRef: MatDialogRef<MconfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData,
  ) {}
}
