import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamic',
})
export class DynamicPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: any, pipeToken: any, pipeArgs?: any[]): any {
    if (!pipeToken) {
      return value;
    } else {
      const pipe = this.injector.get<any>(pipeToken);
      console.log(value);
      return pipe.transform(value, pipeArgs);
    }
  }
}
