import { Pipe, PipeTransform } from '@angular/core';
import { State } from '@models/state';

@Pipe({
  name: 'stateLabel',
})
export class StatePipe implements PipeTransform {
  transform(value: string): any {
    const state = State.find(params => params.value === value);
    return '<span><i class="icon ' + state.icon + ' me-2"></i><span>' + state.label + '</span></span>';
  }
}
