import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'key',
})
export class KeyPipe implements PipeTransform {
  transform(value: any, args: string): any {
    if (!args) {
      return '-';
    }
    const keys = args.split('.');

    if (keys.length > 1) {
      const valueFormated = this.byString(value, args);
      if (!valueFormated || valueFormated === null || valueFormated === '') {
        return '-';
      }
      return valueFormated;
    } else {
      if (value[args] === null || value[args] === '') {
        return '-';
      }
      return value[args];
    }
  }

  byString(o, s): any {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (o !== null) {
        if (k in o) {
          o = o[k];
        } else {
          k = k.replace('[]', '');
          s = s.replace('[]', '');

          return this.byArray(o[k], a[a.length - 1]);
        }
      } else {
        return '-';
      }
    }
    return o;
  }

  byArray(o, s): any {
    if (o && o.length) {
      return o.length > 1 ? o[0][s] + ', [+' + (o.length - 1) + ']' : o[0][s];
    }

    return o;
  }
}
