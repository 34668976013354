import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBoolean',
})
export class ConvertBooleanPipe implements PipeTransform {
  transform(value: any): any {
    return value === true ? 'Oui' : 'Non';
  }
}
