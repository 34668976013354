<div class="filter">
  <div class="row d-flex align-items-center" [formGroup]="form">
    <ng-container *ngIf="filters && filters.length">
      <ng-container *ngFor="let filter of filters">
        <ng-container [ngSwitch]="filter.template">
          <ng-container *ngSwitchCase="'range'">
            <ng-container *ngTemplateOutlet="range; context: { filter: filter }"></ng-container>
          </ng-container>
          <ng-container [ngTemplateOutlet]="deceased" *ngSwitchCase="'deceased'"></ng-container>
          <ng-container *ngSwitchCase="'agency'">
            <ng-container *ngTemplateOutlet="agency; context: { filter: filter }"></ng-container>
          </ng-container>
          <ng-container [ngTemplateOutlet]="googleStars" *ngSwitchCase="'google_stars'"></ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container [ngSwitch]="filter.key">
              <ng-container *ngSwitchCase="'facebook_recommendation_type'">
                <ng-container *ngIf="form.get('platform') && form.get('platform').value === 'facebook'">
                  <ng-container *ngTemplateOutlet="default; context: { filter: filter }"></ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="default; context: { filter: filter }"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'dynamic'">
            <ng-container *ngTemplateOutlet="dynamic; context: { filter: filter }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="col-12 col-lg-auto my-3 mt-lg-0" *ngIf="withSearch">
      <div class="filter__search">
        <mat-form-field>
          <mat-label>Rechercher</mat-label>
          <input aria-label="Recherche" formControlName="search" matInput placeholder="Recherche" type="text" />
        </mat-form-field>
      </div>
    </div>

    <div class="col-12 col-lg-auto mt-3 mb-lg-3">
      <button
        class="w-100"
        color="primary"
        mat-raised-button
        [style.--button-secondary-background]="customerConfig?.style?.listingResetButton.background_color"
        [style.--button-secondary-color]="customerConfig?.style?.listingResetButton.color"
        (click)="resetFilters()"
      >
        Réinitialiser les filtres
      </button>
    </div>
    <div class="col-12 my-3" *ngIf="isMobile || isTablet">
      <button class="w-100" color="accent" mat-raised-button (click)="menuClick.emit()">Valider</button>
    </div>
    <ng-template #googleStars>
      <div
        class="col-12 col-lg-auto my-3 mt-lg-0"
        *ngIf="form.get('platform') && form.get('platform').value === 'google'"
      >
        <label class="me-2">Par note :</label>
        <span class="text-small me-1">{{ form.get('min_stars_google')?.value }}</span>
        <mat-slider
          color="primary"
          [discrete]="false"
          [max]="5"
          [min]="1"
          [showTickMarks]="true"
          [step]="1"
          [style.--mat-mdc-slider-focus-ripple-color]="customerConfig?.style?.toggle | hexaToRgba: '0.2'"
          [style.--mat-mdc-slider-hover-ripple-color]="customerConfig?.style?.toggle | hexaToRgba: '0.5'"
          [style.--mat-mdc-slider-ripple-color]="customerConfig?.style?.toggle"
          [style.--mdc-slider-active-track-color]="customerConfig?.style?.toggle"
          [style.--mdc-slider-focus-handle-color]="customerConfig?.style?.toggle"
          [style.--mdc-slider-handle-color]="customerConfig?.style?.toggle"
          [style.--mdc-slider-hover-handle-color]="customerConfig?.style?.toggle"
          [style.--mdc-slider-inactive-track-color]="customerConfig?.style?.toggle"
          [style.--mdc-slider-with-tick-marks-active-container-color]="customerConfig?.style?.toggleText"
          [style.--mdc-slider-with-tick-marks-inactive-container-color]="customerConfig?.style?.toggle"
        >
          <input formControlName="min_stars_google" matSliderStartThumb />
          <input formControlName="max_stars_google" matSliderEndThumb />
        </mat-slider>
        <span class="text-small ms-1">{{ form.get('max_stars_google')?.value }}</span>
      </div>
    </ng-template>
    <ng-template #agency let-filter="filter">
      <div class="col-12 col-lg-auto my-3 mt-lg-0" *ngIf="agencies && agencies.length">
        <mat-form-field class="agency-filter">
          <mat-label>Agence</mat-label>
          <input
            aria-label=""
            matInput
            placeholder="Sélectionner une agence"
            type="text"
            [formControl]="agencyControl"
            [matAutocomplete]="autoAgency"
          />
          <button
            aria-label="Clear"
            mat-icon-button
            matSuffix
            (click)="clearAgency()"
            *ngIf="form.get('agency')?.value && !loadingAgency"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="loadingAgency">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
          <mat-autocomplete
            #autoAgency="matAutocomplete"
            [displayWith]="formatLabelAgency.bind(this)"
            (optionSelected)="selectAgency($event)"
          >
            <mat-option [value]="option.id" *ngFor="let option of filteredAgency | async">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #range let-filter="filter">
      <div class="col-12 col-lg-auto my-3 mt-lg-0">
        <mat-form-field>
          <mat-label>{{ filter.label }}</mat-label>
          <mat-date-range-input [max]="filter?.maxDate" [rangePicker]="rangePicker">
            <input formControlName="from" matStartDate placeholder="Date de début" />
            <input formControlName="to" matEndDate placeholder="Date de fin" />
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="rangePicker"
            [style.--mat-picker-selected-background]="customerConfig?.style?.hover"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>Annuler</button>
              <button
                color="primary"
                mat-raised-button
                matDateRangePickerApply
                [style.--mdc-protected-button-container-color]="
                  customerConfig?.style?.breadcrumbSecondaryButton.background_color
                "
                [style.--mdc-protected-button-label-text-color]="customerConfig?.style?.breadcrumbSecondaryButton.color"
              >
                Ok
              </button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template #deceased>
      <div class="col-12 col-lg-auto mb-lg-3">
        <mat-form-field>
          <mat-label>Défunt</mat-label>
          <input formControlName="deceased" matInput placeholder="Rechercher un défunt" type="text" />
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template #dynamic let-filter="filter">
      <div class="col-12 col-lg-auto my-3 mt-lg-0">
        <app-filter-dynamic-list [filter]="filter" [formControlName]="filter.key"></app-filter-dynamic-list>
      </div>
    </ng-template>

    <ng-template #default let-filter="filter">
      <div class="col-12 col-lg-auto my-3 mt-lg-0">
        <div class="filter__status">
          <mat-form-field>
            <mat-label>{{ filter.label }}</mat-label>
            <mat-select [formControlName]="filter.key" [multiple]="filter.multiple ? true : false">
              <mat-option selected value="" *ngIf="filter.placeholder && !filter.multiple"
                >{{ filter.placeholder }}
              </mat-option>
              <mat-option [value]="option.value" *ngFor="let option of filter.values">{{ option.label }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-template>
  </div>
</div>
