import { Pipe, PipeTransform } from '@angular/core';
import { CondolenceState } from '@models/condolence-state';

@Pipe({
  name: 'codolenceState',
})
export class CodolenceStateSharedPipe implements PipeTransform {
  transform(value: string): any {
    const state = CondolenceState.find(params => params.value === value);
    return state.label;
  }
}
