<nav aria-label="breadcrumb" class="shadow-sm px-2">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" [ngClass]="{ active: !b.url }" *ngFor="let b of breadcrumb">
      <a [routerLink]="this.profileId ? ['/' + this.profileId + b.url] : [b.url]" *ngIf="b.url">{{ b.label }}</a>
      <ng-container aria-current="page" *ngIf="!b.url">{{ b.label }}</ng-container>
    </li>
  </ol>
  <div class="links">
    <ng-container *ngIf="breadcrumbButton && showBreadCrumbButton">
      <button
        color="accent"
        mat-raised-button
        [matMenuTriggerFor]="menuBreadcrumb"
        [style.--button-primary-background]="customerConfig?.style?.breadcrumbActionsButton.background_color"
        [style.--button-primary-color]="customerConfig?.style?.breadcrumbActionsButton.color"
      >
        <span class="d-none d-lg-block">{{ breadcrumbButton.defaultLabel }}</span>
        <mat-icon class="d-none d-lg-block">arrow_drop_down_circle</mat-icon>
        <mat-icon class="d-flex align-items-center mx-0 d-block d-lg-none">more_vert</mat-icon>
      </button>
      <mat-menu #menuBreadcrumb="matMenu">
        <ng-container *ngFor="let action of breadcrumbButton.actions">
          <button mat-menu-item (click)="menuClick.emit(action.action)" *appHasRole="action.roles">
            {{ action.label }}
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
    <div class="breadcrumb__buttons">
      <ng-content select="[additionalButtons]"></ng-content>
      <button
        class="mx-1"
        mat-raised-button
        type="button"
        [style.--mdc-protected-button-container-color]="
          customerConfig?.style?.breadcrumbSecondaryButton.background_color
        "
        [style.--mdc-protected-button-label-text-color]="customerConfig?.style?.breadcrumbSecondaryButton.color"
        (click)="cancelAction.emit(true)"
        *ngIf="showCancelButton"
      >
        <mat-icon class="d-flex align-items-center mx-0" *ngIf="isMobile || isTablet">cancel</mat-icon>
        <ng-container *ngIf="!isMobile && !isTablet">{{ labelCancelButton }}</ng-container>
      </button>
      <button
        class="mx-1"
        color="accent"
        mat-raised-button
        type="button"
        [disabled]="isLoading"
        [style.--button-primary-background]="customerConfig?.style?.breadcrumbPrimaryButton.background_color"
        [style.--button-primary-color]="customerConfig?.style?.breadcrumbPrimaryButton.color"
        (click)="createAction.emit(true)"
        *ngIf="showCreateButton"
      >
        <mat-icon class="d-flex align-items-center mx-0" *ngIf="isMobile || isTablet">add_circle</mat-icon>
        <ng-container *ngIf="!isMobile && !isTablet">{{ labelCreateButton }}</ng-container>
        <mat-icon *ngIf="isLoading && !isMobile && !isTablet">
          <mat-spinner
            diameter="16"
            [style.--mdc-circular-progress-active-indicator-color]="
              customerConfig?.style?.breadcrumbPrimaryButton.color
            "
          ></mat-spinner>
        </mat-icon>
      </button>
      <button
        class="mx-1"
        color="accent"
        mat-raised-button
        type="button"
        [disabled]="isLoading"
        [style.--button-primary-background]="customerConfig?.style?.breadcrumbPrimaryButton.background_color"
        [style.--button-primary-color]="customerConfig?.style?.breadcrumbPrimaryButton.color"
        (click)="saveAction.emit(true)"
        *ngIf="showSaveButton"
      >
        <mat-icon class="d-flex align-items-center mx-0" *ngIf="isMobile || isTablet">check_circle</mat-icon>
        <ng-container *ngIf="!isMobile && !isTablet">Enregister</ng-container>
        <mat-icon *ngIf="isLoading">
          <mat-spinner
            diameter="16"
            [style.--mdc-circular-progress-active-indicator-color]="
              customerConfig?.style?.breadcrumbPrimaryButton.color
            "
          ></mat-spinner>
        </mat-icon>
      </button>
    </div>
  </div>
</nav>
<mat-progress-bar
  color="accent"
  mode="indeterminate"
  [style.--mdc-linear-progress-active-indicator-color]="customerConfig?.style?.loader"
  [style.--progress-background]="customerConfig?.style?.loader"
  *ngIf="isLoading"
></mat-progress-bar>
