import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateBoolean',
})
export class StateBooleanPipe implements PipeTransform {
  transform(value: any): any {
    const icon = value === true ? 'active' : 'deleted';
    return (
      '<span><i class="icon ' + icon + '"></i><span>' + (value === true ? 'Activé' : 'Désactivé') + '</span></span>'
    );
  }
}
