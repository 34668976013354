import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
/** SERVICES */
/** RXJS */
import { Observable, of, Subscription } from 'rxjs';
/** MODELS */
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { Agency } from '@models/agency.data-list';
import { CustomerConfig } from '@models/customer-config';
import { Filter } from '@models/filter';
import { Params } from '@pages/list.component';
import { MediaQueriesService } from '@services/media-queries.service';
import dayjs from 'dayjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @ViewChild('autoAgency') autocompleteAgency: MatAutocomplete;
  @Input() filters?: Filter[];
  @Input() withSearch?: boolean;
  @Output() menuClick = new EventEmitter<void>();

  form: UntypedFormGroup;

  filteredAgency: Observable<Agency[]>;
  loadingAgency = false;
  agencies: Agency[];
  agencyControl: UntypedFormControl;

  unsubscribe: Subscription[] = [];
  public customerConfig: CustomerConfig;
  isMobile = false;
  isTablet = false;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected fb: UntypedFormBuilder,
    protected mediaQueriesService: MediaQueriesService,
    protected cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
    if (this.filters && this.filters.filter((f: Filter) => !!f.template && f.template === 'agency')) {
      this.agencies = this.activatedRoute.snapshot.data.agencies?.results;
      this.agencyControl = new UntypedFormControl('');
      this.watchAgency();
    }
    this.initFilters();
    this.watchQueryParams();
    this.watchMediaQueries();

    const sub = this.form
      .get('customer')
      ?.valueChanges.pipe(
        debounceTime(900),
        tap(() => this.form.get('profile')?.setValue('')),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }

  watchMediaQueries(): void {
    const sub = this.mediaQueriesService
      .getCurrentMediaQueries()
      .pipe(
        tap(currentMediaQuerie => {
          this.isMobile = currentMediaQuerie === 'sm' ? true : false;
          this.isTablet = currentMediaQuerie === 'md' ? true : false;
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  initFilters(): void {
    this.form = this.fb.group({});

    if (this.filters) {
      this.filters.forEach(f => {
        if (f.hasOwnProperty('key')) {
          this.form.addControl(f.key, new UntypedFormControl(null));
        }

        if (f.hasOwnProperty('keys')) {
          f.keys.forEach(k => {
            this.form.addControl(k, new UntypedFormControl(null));
          });
        }
      });
    }

    if (this.withSearch) {
      this.form.addControl('search', new UntypedFormControl(null));
    }

    const { from, to, agency, platform, min_stars_google, max_stars_google, ...params } =
      this.activatedRoute.snapshot.queryParams;
    if (from) {
      params.from = dayjs(from, ['DD/MM/YYYY']).toISOString();
    }
    if (to) {
      params.to = dayjs(to, ['DD/MM/YYYY']).toISOString();
    }

    if (agency) {
      params.agency = agency;
      this.agencyControl.setValue(agency);
    } else {
      if (this.agencyControl) {
        params.agency = null;
        this.agencyControl.setValue(null);
      }
    }

    if (platform) {
      params.platform = platform;
    } else {
      params.platform = null;
    }
    if (platform && platform === 'google') {
      if (min_stars_google) {
        params.min_stars_google = min_stars_google;
      } else {
        params.min_stars_google = 1;
      }

      if (max_stars_google) {
        params.max_stars_google = max_stars_google;
      } else {
        params.max_stars_google = 5;
      }
    } else {
      params.min_stars_google = 1;
      params.max_stars_google = 5;
    }

    Object.keys(params).forEach(p => {
      if (p.includes('[]')) {
        this.form?.get(p)?.patchValue(typeof params[p] === 'string' ? [params[p]] : [...params[p]], { onlySelf: true });
      } else {
        this.form?.get(p)?.patchValue(params[p], { onlySelf: true });
      }
    });
  }

  watchQueryParams(): void {
    const subForm = this.form.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        tap(values => {
          const { from, to, platform, min_stars_google, max_stars_google, ...params } = values;
          params.from = from ? dayjs(from).format('DD/MM/YYYY') : null;
          params.to = to ? dayjs(to).format('DD/MM/YYYY') : null;

          params.min_stars_google = platform === 'google' ? (min_stars_google ? min_stars_google : 1) : null;
          params.max_stars_google = platform === 'google' ? (max_stars_google ? max_stars_google : 5) : null;
          params.platform = platform ? platform : null;

          this.router.navigate([], {
            queryParams: { ...params, page: 1 },
            queryParamsHandling: 'merge',
            relativeTo: this.activatedRoute,
          });
        }),
      )
      .subscribe();
    this.unsubscribe.push(subForm);
  }

  selectAgency(item: MatAutocompleteSelectedEvent): void {
    if (item.option.value) {
      this.updateForm({ agency: item.option.value });
    }
  }

  updateForm(data: Params): void {
    this.form.patchValue(data);
  }

  watchAgency(): void {
    this.filteredAgency = this.agencyControl?.valueChanges.pipe(
      filter(x => typeof x === 'string'),
      tap(() => (this.loadingAgency = true)),
      debounceTime(800),
      startWith(''),
      switchMap(value => {
        return of(this.agencies.filter(a => a.name.toLowerCase().includes(value)));
      }),
      tap(() => (this.loadingAgency = false)),
    );
  }

  resetFilters(): void {
    if (this.agencyControl) {
      this.agencyControl.setValue('', { emitEvent: true });
    }

    this.form.reset();
    if (this.menuClick) {
      this.menuClick.emit();
    }
  }

  clearAgency(): void {
    this.agencyControl.setValue('', { emitEvent: true });
    this.updateForm({ agency: null });
  }

  formatLabelAgency(val: any): string {
    if (!val || val === '') {
      return '';
    }

    if (this.agencies && this.agencies.length) {
      const agency = this.agencies.find(c => c.id === parseInt(val, 10));
      if (agency) {
        return `${agency.name}`;
      }
    }

    return val;
  }
}
