import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Filter } from '@models/filter';
import { ProfileList } from '@models/profile.data-list';
import { BaseService } from '@services/base.service';
import { DEFAULT_ACTION, DEFAULT_TIME } from '@utils/const/snackbar';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, filter, finalize, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-filter-dynamic-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatOptionModule,
    NgForOf,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgIf,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDynamicListComponent),
      multi: true,
    },
  ],
  templateUrl: './filter-dynamic-list.component.html',
  styleUrl: './filter-dynamic-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDynamicListComponent implements ControlValueAccessor {
  @Input() filter: Filter;
  @Input() dependOn?: string[];
  @ViewChild('auto', { static: true }) autocomplete: MatAutocomplete;

  baseService = inject(BaseService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly snackBar: MatSnackBar = inject(MatSnackBar);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  data: any;

  control = new FormControl('');
  isLoading = false;

  filteredData = this.control.valueChanges.pipe(
    filter(x => typeof x === 'string'),
    tap(() => (this.isLoading = true)),
    debounceTime(800),
    startWith(this.control.value),
    switchMap(value => this.load(value)),
  );

  onChange: (value: string) => void;
  onTouched: () => void;

  writeValue(value: string): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  load(search: string): Observable<ProfileList> {
    this.isLoading = true;
    let params = new HttpParams({
      fromObject: {
        maxPerPage: '3000',
        search,
        state: 'active',
      },
    });

    if (this.filter.dependOn) {
      this.filter.dependOn.forEach(d => {
        params = params.append(
          d,
          this.activatedRoute.snapshot.queryParams[d] ? this.activatedRoute.snapshot.queryParams[d] : null,
        );
      });
    }

    return this.baseService.get(`/admin/${this.filter.key}/`, { params }).pipe(
      tap((data: any) => {
        this.data = data.results;
      }),
      finalize(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      }),
      catchError(err => {
        this.snackBar.open('Une erreur est survenue lors du chargement des conseillers', DEFAULT_ACTION, {
          duration: DEFAULT_TIME,
          panelClass: 'error',
        });
        return throwError(() => err);
      }),
    );
  }

  clearControl(): void {
    this.control.setValue('');
    this.onChange(null);
  }

  formatLabel(val: any): string {
    if (!val || val === '') {
      return '';
    }
    if (this.data) {
      const entity = this.data.find(p => p.id === val);
      if (entity) {
        return `${entity.name}`;
      }
    }

    return val;
  }

  updateControl(item: MatAutocompleteSelectedEvent): void {
    this.onChange(item.option.value);
    this.cdr.detectChanges();
  }
}
