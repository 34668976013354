import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'higlightTerm',
})
export class HiglightTermPipe implements PipeTransform {
  transform(value: any, args: string): any {
    if (args && value) {
      value = String(value); // make sure its a string
      if (args.length > 0) {
        const re = new RegExp(args, 'gi');
        return value.replace(re, '<mark>' + args + '</mark>');
      }
    }
    return value;
  }
}
