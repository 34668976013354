<mat-form-field>
  <mat-label>
    {{ filter.label }}
  </mat-label>
  <input
    aria-label=""
    matInput
    type="text"
    [formControl]="control"
    [matAutocomplete]="auto"
    [placeholder]="filter.placeholder"
  />
  <button aria-label="Clear" mat-icon-button matSuffix (click)="clearControl()" *ngIf="control.value && !isLoading">
    <mat-icon>close</mat-icon>
  </button>
  <mat-icon matSuffix *ngIf="isLoading">
    <mat-spinner diameter="20"></mat-spinner>
  </mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    [autoSelectActiveOption]="true"
    [displayWith]="formatLabel.bind(this)"
    (optionSelected)="updateControl($event)"
  >
    <mat-option [value]="option.id" *ngFor="let option of (filteredData | async)?.results">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
