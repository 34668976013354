export const CondolenceState = [
  {
    label: 'Approuvé',
    value: 'published',
    icon: 'active',
  },
  {
    label: 'En attente',
    value: 'draft',
    icon: 'disabled',
  },
  {
    label: 'Supprimé',
    value: 'deleted',
    icon: 'deleted',
  },
];

export enum CondolenceStateEnum {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  DELETED = 'deleted',
}
