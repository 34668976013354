<h1 mat-dialog-title [style.--mat-dialog-title]="data?.customerConfig?.style?.headings">
  {{ data?.title || 'Suppression' }}
</h1>
<div mat-dialog-content>
  <p>{{ data?.prompt || 'Êtes-vous sur de vouloir supprimer cet élément ?' }}</p>
</div>
<div class="row border-top" mat-dialog-actions>
  <div class="col col-12 col-md-6">
    <button
      class="mx-1 w-100"
      color="secondary"
      mat-dialog-close
      mat-raised-button
      type="button"
      [style.--mdc-protected-button-container-color]="data?.customerConfig?.style?.modalCancelButton.background_color"
      [style.--mdc-protected-button-label-text-color]="data?.customerConfig?.style?.modalCancelButton.color"
    >
      {{ data?.cancel || 'Annuler' }}
    </button>
  </div>
  <div class="col col-12 col-md-6">
    <button
      class="mx-1 w-100"
      color="accent"
      mat-dialog-close="true"
      mat-raised-button
      type="button"
      [style.--button-primary-background]="data?.customerConfig?.style?.modalSaveButton.background_color"
      [style.--button-primary-color]="data?.customerConfig?.style?.modalSaveButton.color"
    >
      {{ data?.confirm || 'Valider' }}
    </button>
  </div>
</div>
