import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
/** MODELS */
import { Breadcrumb } from '@models/breadcrumb';
import { BreadcrumbButton } from '@models/breadcrumb-button';
/** RXJS */
import { CustomerConfig } from '@models/customer-config';
import { MediaQueriesService } from '@services/media-queries.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-mbreadcrumb',
  templateUrl: './mbreadcrumb.component.html',
  styleUrls: ['./mbreadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MbreadcrumbComponent implements OnInit, OnDestroy {
  @Input() showCancelButton = false;
  @Input() showCreateButton = false;
  @Input() showSaveButton = false;
  @Input() labelCreateButton = '';
  @Input() labelCancelButton = 'Annuler';
  @Input() breadcrumbButton?: BreadcrumbButton;
  @Input() showBreadCrumbButton = true;
  @Input() isLoading?: boolean = false;
  @Output() createAction = new EventEmitter<boolean>();
  @Output() saveAction = new EventEmitter<boolean>();
  @Output() cancelAction = new EventEmitter<boolean>();
  @Output() menuClick? = new EventEmitter<string>();

  private unsubscribe: Subscription[] = [];
  breadcrumb: Breadcrumb[];
  profileId: string;
  customerConfig: CustomerConfig;
  isMobile = false;
  isTablet = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mediaQueriesService: MediaQueriesService,
    private cdr: ChangeDetectorRef,
  ) {
    this.customerConfig = this.activatedRoute.snapshot.data.customerConfig;
    this.profileId = this.activatedRoute.snapshot.paramMap.get('profileId');
  }

  ngOnInit(): void {
    this.watchMediaQueries();
    this.breadcrumb = this.activatedRoute.snapshot.data.breadcrumb.map(route => {
      // Interpolating :ids in breadcrumbs labels
      const matchedIdInRouteLabel = route.label.match(/:([a-z]+)/);
      if (matchedIdInRouteLabel) {
        const routeIdIndex = matchedIdInRouteLabel[1];
        const label = route.label.replace(/:[a-z]+/, this.activatedRoute.snapshot.data[routeIdIndex].id);
        return { ...route, label };
      }
      return route;
    });
  }

  watchMediaQueries(): void {
    const sub = this.mediaQueriesService
      .getCurrentMediaQueries()
      .pipe(
        tap(currentMediaQuerie => {
          this.isMobile = currentMediaQuerie === 'sm' ? true : false;
          this.isTablet = currentMediaQuerie === 'md' ? true : false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
    this.unsubscribe.push(sub);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => (sb && !sb.closed ? sb.unsubscribe() : null));
  }
}
